import styled from "styled-components";
import { BP, Rem } from "../../commons/Theme";

export const StyledBusinessProducts = styled.div``;

export const StyledBusinessProductsItems = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  &:after {
    content: '';
    display: block;
    min-width: ${Rem(50)};
    max-width: ${Rem(50)};
    height: 1px;

    @media (${BP.ipad}) {
      display: none;
    }
  }

  @media (${BP.ipad}) {
    justify-content: space-evenly;
    gap: ${Rem(40)};
  }
`;

export const StyledBusinessProductsItemsGroup = styled.div`
  display: flex;

  @media (${BP.ipad}) {
    flex-direction: column;
    gap: ${Rem(40)};
  }
`;

export const StyledBusinessProductsItem = styled.div`
  position: relative;
  color: white;
  margin-left: ${Rem(16)};

  width: 90vw;
  height: ${Rem(450)};
  @media (${BP.ipad}) {
    width: 100%;
    height: ${Rem(400)};
    margin-left: 0;
  }

  .gatsby-image-wrapper {
    width: 90vw;
    height: ${Rem(450)};
    @media (${BP.ipad}) {
      width: 100%;
      height: ${Rem(400)};
    }
  }
`;

export const StyledBusinessProductsItemContent = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${Rem(20)};
  padding: ${Rem(20)};
`;

export const StyledBusinessProductsItemTitle = styled.h2`
  font-size: ${Rem(24)};
  line-height: ${Rem(30)};
  font-weight: 700;
  margin-top: auto;

  @media (${BP.tablet}) {
    font-size: ${Rem(30)};
    line-height: ${Rem(38)};
  }
`;

export const StyledBusinessProductsItemSubtitle = styled.h3`
  font-size: ${Rem(14)};
  line-height: ${Rem(19)};
  font-weight: normal;

  @media (${BP.tablet}) {
    font-size: ${Rem(20)};
    line-height: ${Rem(25)};
  }
`;
