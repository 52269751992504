import { Button } from "@3beehivetech/components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useContext, useState } from "react";
import { StyledGridRow } from "../../commons/Grid";
import { Colors } from "../../commons/Theme";
import { DataContext } from "../../contexts/DataContext";
import useNavigate from "../../hooks/useNavigate";
import Icon, { icons } from "../Icon/Icon";
import Scroller from "../Scroller/Scroller";

import {
  StyledBusinessProducts,
  StyledBusinessProductsItems,
  StyledBusinessProductsItemsGroup,
  StyledBusinessProductsItem,
  StyledBusinessProductsItemContent,
  StyledBusinessProductsItemTitle,
  StyledBusinessProductsItemSubtitle,
} from "./style";

const BusinessProducts = ({
  hives, apiary, forest, beenergy,
}) => {
  const { labels } = useContext(DataContext);

  return (
    <StyledGridRow isFullMobile>
      <StyledBusinessProducts>
        <Scroller>
          <StyledBusinessProductsItems>
            <StyledBusinessProductsItemsGroup>
              {hives && (<BusinessProductsItem className="swiper-slide"
                title={hives.title}
                subtitle={hives.subtitle}
                button={labels.request}
                link={hives.link}
                image={hives.image}
              />)}
              {apiary && (<BusinessProductsItem className="swiper-slide"
                title={apiary.title}
                subtitle={apiary.subtitle}
                button={labels.request}
                link={apiary.link}
                image={apiary.image}
              />)}
            </StyledBusinessProductsItemsGroup>
            <StyledBusinessProductsItemsGroup>
              {forest && (<BusinessProductsItem className="swiper-slide"
                title={forest.title}
                subtitle={forest.subtitle}
                button={labels.request}
                link={forest.link}
                image={forest.image}
              />)}
              {beenergy && (<BusinessProductsItem className="swiper-slide"
                title={beenergy.title}
                subtitle={beenergy.subtitle}
                button={labels.discoverMore}
                link={beenergy.link}
                image={beenergy.image}
              />)}
            </StyledBusinessProductsItemsGroup>
          </StyledBusinessProductsItems>
        </Scroller>
      </StyledBusinessProducts>
    </StyledGridRow>
  );
};

const BusinessProductsItem = ({
  title, subtitle, button, link, image, onClickButton,
}) => {
  const [isHover, setIsHover] = useState(false);
  const { pageImages } = useContext(DataContext);
  const imageData = pageImages.find(e => e.path === image);

  return (
    <StyledBusinessProductsItem>
      <GatsbyImage
        imgStyle={{ borderRadius: "10px", filter: "brightness(0.75)" }}
        image={getImage(imageData)}
        alt={imageData?.alt} />

      <StyledBusinessProductsItemContent>

        <StyledBusinessProductsItemTitle dangerouslySetInnerHTML={{ __html: title }} />
        <StyledBusinessProductsItemSubtitle dangerouslySetInnerHTML={{ __html: subtitle }} />

        <Button
          style={{ display: button && link ? "block" : "none" }}
          onClick={useNavigate(link, onClickButton)}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}>
          {button}{" "}
          {isHover
            ? <Icon icon={icons.arrowRight} color={Colors.yellow} />
            : <Icon icon={icons.arrowRight} color={Colors.white} />
          }
        </Button>
      </StyledBusinessProductsItemContent>
    </StyledBusinessProductsItem>
  );
};

export default BusinessProducts;
