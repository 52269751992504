import React, { useContext } from "react";
import BusinessIconText from "../components/BusinessIconText/BusinessIconText";
import BusinessProducts from "../components/BusinessProducts/BusinessProducts";
import ContactForm from "../components/ContactFormTerrain/ContactFormTerrain";
import Faq from "../components/Faq/Faq";
import Spacer from "../components/Spacer/Spacer";
import Intro from "../components/Intro_v2/Intro_v2";
import Meta from "../components/Meta/Meta";
import { DataContext } from "../contexts/DataContext";
import {
  Hero, HeroImageContainer, HeroHeader, Button, 
} from "@3beehivetech/components";
import { StyledTitleHero, StyledSubTitleHero } from "../commons/Typography";
import useNavigate from "../hooks/useNavigate";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const TerrenoBiodiversita = () => {
  const { 
    pageLabels, 
    pageImages,
    i18nLink,
  } = useContext(DataContext);
  const imageData = pageImages.find(e => e.path === "/2020/images/terreno-biodiversita/terreno.jpg");

  return (
    <>
      <Meta />
      <Hero className="apply-typography">
        <HeroImageContainer style={{ position: "relative", minHeight: "500px" }}>
          <GatsbyImage loading="eager" layout="fullWidth" objectFit="cover" objectPosition="right" opacity="1" style={{ "max-height": "750px", "display":"block" }}
            image={getImage(imageData)} alt={imageData.alt} />
          <div style={{
            position: "absolute", zIndex: 1, top: 0, left: 0, width: "100%", background: "linear-gradient(rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0.2) 30%, rgba(255, 255, 255, 0.2) 72%, rgb(255, 255, 255) 100%)", 
          }} />
        </HeroImageContainer>
        <HeroHeader
          title={<StyledTitleHero dangerouslySetInnerHTML={{ __html: pageLabels.hero.title }} />}
          description={<StyledSubTitleHero style={{ paddingTop:"20px" }} dangerouslySetInnerHTML={{ __html:pageLabels.hero.paragraph }}/> }
          variant='transparent'>
          <div className="mt-8">
            <Button onClick={useNavigate("#intro_terreni")}>{pageLabels.hero.cta}</Button>
          </div>
        </HeroHeader>
      </Hero>

      <Spacer size={10}/>
      <Intro 
        bigText={pageLabels.story.title}
        textAlign="center"
        id="intro_terreni"
      />
      <BusinessIconText
        id="intro_terreni"
        items={[{
          image: "/2020/images/business/HR_icon.png",
          label: pageLabels.story.hr.label,
          text: pageLabels.story.hr.text,
        },{
          image: "/2020/images/business/communication_icon.png",
          label: pageLabels.story.comunication.label,
          text: pageLabels.story.comunication.text,
        },{
          image: "/2020/images/business/CSR_RSI_icon.png",
          label: pageLabels.story.csr.label,
          text: pageLabels.story.csr.text,
        }]}
      />

      <div style={{ height: "5.5rem" }}></div>

      <BusinessProducts
        apiary={{ ...pageLabels.products.forest, image: "/2020/images/business/products/background_forest.jpg", link: "#contacts" }}
        beenergy={{ ...pageLabels.products.beenergy, image: "/2020/images/business/products/background_beenergy.jpg", link: i18nLink("/business/beenergy/") }}
      />     

      <Intro 
        id="contacts"
        bigText={pageLabels.contact.title}
      />
      <ContactForm/>
      <div style={{ height: "6.5rem" }}></div>
      <Faq
        data={pageLabels.faq.dataFile.data}
      />
    </>
  );
};

export default TerrenoBiodiversita;
